// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(@color; @background; @border; @hoverbg; @hovercolor) {
  color: @color;
  background-color: @background;
  //border-color: @border;
  border-color: transparent;

  &:focus,
  &.focus {
    //color: @color;
    color: @hovercolor;
    //background-color: darken(@background, 10%);
    background-color: @hoverbg;
    //border-color: spin(@border, 180);
  }
  &:hover {
    //color: @color;
    color: @hovercolor;
    //background-color: darken(@background, 10%);
    background-color: @hoverbg;
    //border-color: darken(@border, 12%);
    //border-color: spin(@border, 180);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @hovercolor;
    //background-color: darken(@background, 10%);
    background-color: @hoverbg;
    background-image: none;
    //border-color: darken(@border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: @hovercolor;
      //background-color: darken(@background, 17%);
      background-color: darken(@hoverbg, 17%);
      //border-color: darken(@border, 25%);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: @hoverbg;
      //border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  padding: @padding-vertical @padding-horizontal !important;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;
}
