#art-submission-node-form #edit-next {
	.btn-big();
	.btn-info();
}

#webform-client-form-259 .webform-submit {
	.btn-big();
	.btn-success();
}

/* Terms and Conditions page */
#node-89 {
	font-size: 12px;

	h4 {
		margin-bottom: 10px;
		margin-top: 20px;
	}
}